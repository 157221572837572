<template>
    <div class="accountWrap">
        <div class="facilityBox">
            <el-form class="manageForm" :inline="true">
                <el-form-item label="单位名称：" prop="enterpriseName">
                    <el-input class="fromInp" v-model="enterpriseName" placeholder="输入单位名称"
                        @keyup.enter.native="search()"></el-input>
                </el-form-item>
                <el-form-item label="时间：" prop="date">
                    <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                        start-placeholder="开始时间" end-placeholder="结束时间" @change="search()">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                    <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
                    <!-- <el-button type="primary" size="small" icon="el-icon-download" @click="exportTable(1)"
                        :disabled="!tableData.length">导出表格
                    </el-button> -->
                </el-form-item>
            </el-form>
            <el-table :data="tableData1" :span-method="objectSpanMethod1" border style="width: 100%; margin-top: 20px"
                v-loading="loading1" :header-cell-style="{ background: '#ddd', color: '#333' }">
                <el-table-column prop="type" label="" align="center">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="num1" label="数量" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="openDetail(scope.row)">{{ scope.row.num1 }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="num2" label="临登数量" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="openDetail(scope.row, '1')">{{ scope.row.num2 }}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="tableData2" :span-method="objectSpanMethod2" border style="width: 100%; margin-top: 20px"
                v-loading="loading1" :header-cell-style="{ background: '#ddd', color: '#333' }">
                <el-table-column prop="type" label="" align="center">
                </el-table-column>
                <el-table-column prop="name" label="运单类型" align="center">
                </el-table-column>
                <el-table-column prop="num" label="数量" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="openDetail(scope.row)">{{ scope.row.num }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.amount | formatMoney }}</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="tableData3" :span-method="objectSpanMethod3" border style="width: 100%; margin-top: 20px"
                v-loading="loading1" :header-cell-style="{ background: '#ddd', color: '#333' }">
                <el-table-column prop="type" label="" align="center">
                </el-table-column>
                <el-table-column prop="name" label="开票类型" align="center">
                </el-table-column>
                <el-table-column prop="num" label="数量" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="openDetail(scope.row)">{{ scope.row.num }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="金额" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.amount | formatMoney }}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog width="1600px" class="deep_dialog" :visible.sync="detailDialog" append-to-body v-if="detailDialog">
            <el-form class="manageForm" :model="params" :inline="true">
                <el-form-item label="关键字：" prop="keyValue">
                    <el-input class="fromInp" v-model="params.keyValue"
                        :placeholder="detailType.name == '车辆' ? '输入车牌号' : '输入姓名、手机号'"></el-input>
                </el-form-item>
                <el-form-item label="运单号：" prop="waybillID" label-width="96px" v-if="detailType.type != '运力'">
                    <el-input class="fromInp" v-model="params.waybillID" placeholder="输入运单号">
                    </el-input>
                </el-form-item>
                <el-form-item label="审核状态：" prop="status" v-if="detailType.type == '运力' && type!=1">
                    <el-select v-model="params.status" clearable placeholder="请选择审核状态" @change="searchDetail()">
                        <el-option label="全部" value="0"></el-option>
                        <el-option label="审核中" value="1"></el-option>
                        <el-option label="审核失败" value="2"></el-option>
                        <el-option label="已认证" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="searchDetail()" icon="el-icon-search">搜索
                    </el-button>
                    <el-button type="primary" size="medium" @click="resetDetailForm()" icon="el-icon-delete">清空
                    </el-button>
                    <!-- <el-button type="primary" size="small" icon="el-icon-download" @click="exportTable(2)"
                        :disabled="!detailTable.length">导出表格
                    </el-button> -->
                </el-form-item>
            </el-form>
            <el-table :data="detailTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading" v-if="detailType.name == '司机' && type != '1'">
                <el-table-column align="center" fixed="left" prop="rownumber" label="序号" width="100"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="姓名" width="110" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="身份证号" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TelPhone" label="手机号" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankCardNo" label="银行卡号" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="IsSM" label="运营商实名" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="AccountStatus" label="电子账户状态" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="180"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverType" label="归属类型" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Status" label="审核状态" width="130" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Remark" label="驳回原因" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SubmitDatetime" label="上传时间" width="180" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <el-table :data="detailTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading" v-if="detailType.name == '司机' && type == '1'">
                <el-table-column align="center" fixed="left" prop="rownumber" label="序号" width="100"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="身份证号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TelPhone" label="手机号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleCarNumber" label="车牌号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" label="证件">
                    <template slot-scope="scope">
                        <el-button type="primary" :loading="loading" size="small" @click="getPersonInfo(scope.row,scope.row.UserID)"
                            icon="el-icon-tickets">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="detailTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading" v-if="detailType.name == '车辆'">
                <el-table-column align="center" fixed="left" prop="rownumber" label="序号" width="100"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleCarNumber" label="车牌号" width="110" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CarNumberColor" label="车牌颜色" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleTypeName" label="车辆类型" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleLoadPersons" label="核载人数" width="130"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PowerTypeName" label="能源类型" width="130" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleTotalWeight" label="自重（吨）" width="100"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleLoad" label="载重（吨）" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleLoadWeight" label="总重（吨）" width="120"
                    show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleLength" label="车长（米）" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VehicleWidth" label="车宽（米）" width="130" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Status" label="审核状态" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SubmitDatetime" label="上传时间" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Remark" label="驳回原因" width="180" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <el-table :data="detailTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading" v-if="detailType.type != '运力'">
                <el-table-column type="index" align="center" fixed="left" label="序号" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillID" fixed="left" label="运单号" width="210"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="210" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneType" label="场景类型" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.DriverName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="110" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.DriverPhone }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.DriverIDCard }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="车牌" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.CarNumber }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="LoadNetWeight" label="装货净重（吨）" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="签收时间" width="160" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="300" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.OriginAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="300" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.ArriveAddress }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="数量" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TransportFee" label="运费" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{ scope.row.TransportFee | formatMoney }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Status" label="运单状态" width="110" show-overflow-tooltip
                    v-if="detailType.type == '运单'">
                </el-table-column>
                <el-table-column align="center" prop="Status" label="发票状态" width="110" show-overflow-tooltip
                    v-if="detailType.type == '发票'">
                </el-table-column>
                <el-table-column align="center" prop="ImportTime" label="上传时间" width="150" show-overflow-tooltip
                    v-if="detailType.name == '新增运单'">
                </el-table-column>
                <el-table-column align="center" prop="RiskTime" label="风控时间" width="150" show-overflow-tooltip
                    v-if="detailType.name == '已过风控' || detailType.name == '未过风控'">
                </el-table-column>
                <el-table-column align="center" prop="RiskTime" label="人工审核时间" width="150" show-overflow-tooltip
                    v-if="detailType.name == '已过人工' || detailType.name == '未过人工'">
                </el-table-column>
                <el-table-column align="center" prop="RiskTime" label="风控通过时间" width="150" show-overflow-tooltip
                    v-if="detailType.name == '未申请开票'">
                </el-table-column>
                <el-table-column align="center" prop="InvoiceApplyDatetime" label="申请开票时间" width="150"
                    show-overflow-tooltip v-if="detailType.name == '已申请开票'">
                </el-table-column>
                <el-table-column align="center" prop="InvoicePrintDatetime" label="开票时间" width="150"
                    show-overflow-tooltip v-if="detailType.name == '已开发票'">
                </el-table-column>
            </el-table>
            <div style="margin-top: 10px">
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    :page-sizes="[10, 20, 30, 40, 50, 100]" @size-change="paginationSizeChange"
                    layout="total,sizes, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </el-dialog>
        <!--人员信息弹框-->
        <el-dialog top="15vh" class="dialog" :visible.sync="personDialog" width="1300px" :close-on-click-modal="false">
            <span slot="title">人员信息</span>
            <DriverInfo :driverInfo="driverInfo" />
        </el-dialog>
    </div>
</template>

<script>
import {
    getDriverDetails
} from "@/api/common/common";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import { getDailyCapacity, getDailyWaybill, getDailyInvoiceBill, getDailyDriverDetails, getDailyCarDetails, getDailyWaybillDetails, getDailyInvoiceDetails, DownloadSummaryReport, DownloadDriverReport, DownloadCarReport, DownloadWaybillReport, DownloadInvoiceReport, getDailyDriverTaxRegDetails } from '@/api/platform/dailyReport/index'
export default {
    data() {
        return {
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            capacityData: {
                TotalCarCounts: {},
                TotalDriverCounts: {}
            }, //运力数据
            waybillData: {
                ManualNoPassWaybill: {},
                ManualPassWaybill: {},
                NewWaybill: {},
                RiskNoPassWaybill: {},
                RiskPassWaybill: {}
            }, //运单数据
            invoiceBillData: {
                ApplyWaybill: {},
                InvoiceWaybill: {},
                NoApplyWaybill: {}
            },//发票数据
            // 表单数据
            tableData1: [],
            tableData2: [],
            tableData3: [],
            loading1: false,
            // 已选中的日期
            date: ['', ''],
            enterpriseName: '',
            detailDialog: false,
            // 数据请求参数
            params: {
                status: '0',
                keyValue: '',
                waybillID: ''
            },
            detailTable: [],
            // 表格loading
            loading: true,
            detailType: {},
            type: '', // 1为临登司机
            personDialog: false, // 人员信息弹框
            driverInfo: {}, //  司机 收款人信息
            loading: false
        }
    },
    created() {
        // 获取数据列表
        this.getDataList()
    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.tableData1 = []
            this.tableData2 = []
            this.tableData3 = []
            let ajaxFun = [getDailyCapacity, getDailyWaybill, getDailyInvoiceBill]
            let data = {
                startDatetime: this.date ? this.date[0] : '',
                endDatetime: this.date ? this.date[1] : '',
                enterpriseName: this.enterpriseName
            };
            ajaxFun.map(fun => {
                this.loading1 = true
                fun(data).then(res => {
                    if (String(fun).indexOf('getDailyCapacity') != -1) {
                        this.capacityData = res || {
                            TotalCarCounts: [],
                            TotalDriverCounts: [],
                            TotalTaxRegCounts: []
                        }
                        this.tableData1.push({
                            type: '运力',
                            name: '司机',
                            num1: this.capacityData.TotalDriverCounts[0].TotalDriverCounts + '位',
                            amount: null,
                            id: 1,
                            num2: this.capacityData.TotalTaxRegCounts[0].TotalTaxRegCounts + '位'
                        }, {
                            type: '运力',
                            name: '车辆',
                            num1: this.capacityData.TotalCarCounts[0].TotalCarCounts + '辆',
                            amount: null,
                            id: 2,
                            num2: null
                        })
                    }
                    if (String(fun).indexOf('getDailyWaybill') != -1) {
                        this.waybillData = res || {
                            ManualNoPassWaybill: {},
                            ManualPassWaybill: {},
                            NewWaybill: {},
                            RiskNoPassWaybill: {},
                            RiskPassWaybill: {}
                        }
                        this.tableData2.push({
                            type: '运单',
                            name: '新增运单',
                            num: this.waybillData.NewWaybill[0].TotalWaybillCounts + '条',
                            amount: this.waybillData.NewWaybill[0].NewTotalAmount,
                            id: 1
                        }, {
                            type: '运单',
                            name: '已过风控',
                            num: this.waybillData.RiskPassWaybill[0].RiskPassCounts + '条',
                            amount: this.waybillData.RiskPassWaybill[0].RiskPassAmount,
                            id: 2
                        }, {
                            type: '运单',
                            name: '未过风控',
                            num: this.waybillData.RiskNoPassWaybill[0].RiskNoPassCounts + '条',
                            amount: this.waybillData.RiskNoPassWaybill[0].RiskNoPassAmount,
                            id: 3
                        }, {
                            type: '运单',
                            name: '已过人工',
                            num: this.waybillData.ManualPassWaybill[0].ManualPassCounts + '条',
                            amount: this.waybillData.ManualPassWaybill[0].ManualPassAmount,
                            id: 4
                        }, {
                            type: '运单',
                            name: '未过人工',
                            num: this.waybillData.ManualNoPassWaybill[0].ManualNoPassCounts + '条',
                            amount: this.waybillData.ManualNoPassWaybill[0].ManualNoPassAmount,
                            id: 5
                        })
                    }
                    if (String(fun).indexOf('getDailyInvoiceBill') != -1) {
                        this.invoiceBillData = res || {
                            ApplyWaybill: {},
                            InvoiceWaybill: {},
                            NoApplyWaybill: {}
                        }
                        this.tableData3.push({
                            type: '发票',
                            name: '未申请开票',
                            num: this.invoiceBillData.NoApplyWaybill[0].NoApplyCounts + '条',
                            amount: this.invoiceBillData.NoApplyWaybill[0].NoApplyAmount,
                            id: 1
                        }, {
                            type: '发票',
                            name: '已申请开票',
                            num: this.invoiceBillData.ApplyWaybill[0].ApplyCounts + '条',
                            amount: this.invoiceBillData.ApplyWaybill[0].ApplyAmount,
                            id: 2
                        }, {
                            type: '发票',
                            name: '已开票',
                            num: this.invoiceBillData.InvoiceWaybill[0].InvoiceCounts + '条',
                            amount: this.invoiceBillData.InvoiceWaybill[0].InvoiceAmount,
                            id: 3
                        })
                    }
                }).finally(() => {
                    this.loading1 = false;
                })
            })
        },
        // 查询人员信息
        getPersonInfo(item,UserID) {
            this.loading = true;
            getDriverDetails({ UserID,AscriptionUserID: item.AscriptionUserID })
                .then((res) => {
                    data = Object.assign(
                        {},
                        res.data.driver_baseInfo,
                        res.data.driver_confirmInfo
                    );
                    this.driverInfo = data;
                    this.personDialog = true;
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
            let spanArr = []
            let pos = null
            for (let i = 0; i < this.tableData1.length; i++) {
                let data = this.tableData1
                if (i === 0) {
                    spanArr.push(1);
                    pos = 0;
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].type === data[i - 1].type) {
                        spanArr[pos] += 1;
                        spanArr.push(0);
                    } else {
                        spanArr.push(1);
                        pos = i;
                    }
                }
            }
            if (columnIndex === 0) {
                const _row = spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                };
            }
        },
        objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
            let spanArr = []
            let pos = null
            for (let i = 0; i < this.tableData2.length; i++) {
                let data = this.tableData2
                if (i === 0) {
                    spanArr.push(1);
                    pos = 0;
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].type === data[i - 1].type) {
                        spanArr[pos] += 1;
                        spanArr.push(0);
                    } else {
                        spanArr.push(1);
                        pos = i;
                    }
                }
            }
            if (columnIndex === 0) {
                const _row = spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                };
            }
        },
        objectSpanMethod3({ row, column, rowIndex, columnIndex }) {
            let spanArr = []
            let pos = null
            for (let i = 0; i < this.tableData3.length; i++) {
                let data = this.tableData3
                if (i === 0) {
                    spanArr.push(1);
                    pos = 0;
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].type === data[i - 1].type) {
                        spanArr[pos] += 1;
                        spanArr.push(0);
                    } else {
                        spanArr.push(1);
                        pos = i;
                    }
                }
            }
            if (columnIndex === 0) {
                const _row = spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                };
            }
        },
        // 导出汇总表
        exportTable(type) {
            this.loading = true
            let params = {}
            let ajaxFun = ''
            if (type == 1) {
                ajaxFun = DownloadSummaryReport
                params = {
                    startDatetime: this.date ? this.date[0] : '',
                    endDatetime: this.date ? this.date[1] : '',
                    enterpriseName: this.enterpriseName
                }
            } else {
                if (this.detailType.name == '司机') ajaxFun = DownloadDriverReport
                if (this.detailType.name == '车辆') ajaxFun = DownloadCarReport
                if (this.detailType.type == '运单') ajaxFun = DownloadWaybillReport
                if (this.detailType.type == '发票') ajaxFun = DownloadInvoiceReport
                params = {
                    startDatetime: this.date ? this.date[0] : '',
                    endDatetime: this.date ? this.date[1] : '',
                    enterpriseName: this.enterpriseName,
                    pageSize: this.pagination.pagesize,
                    pageIndex: this.pagination.page,
                    keyValue: this.params.keyValue,
                    ...((this.detailType.type == '运力') ? {
                        status: Number(this.params.status)
                    } : {}),
                    ...((this.detailType.type != '运力') ? {
                        optType: this.detailType.id,
                        waybillID: this.params.waybillID
                    } : {})
                }
            }
            ajaxFun(params).then(res => {
                window.location.href = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        // 清空搜索
        resetForm() {
            this.loading = true
            this.date = ['', ''];
            this.enterpriseName = '';
            this.getDataList()
        },
        // 搜索
        search() {
            this.loading = true
            this.getDataList()
        },
        openDetail(row, type) {
            this.loading = true
            this.detailDialog = true
            this.detailType = row
            this.type = type
            this.params = {
                status: '0',
                keyValue: '',
                waybillID: ''
            }
            this.pagination.page = 1
            this.getDetailData(row)
        },
        getDetailData(row) {
            this.detailTable = []
            let ajaxFun = ''
            if (row.name == '司机') ajaxFun = getDailyDriverDetails
            if (this.type && this.type == '1') ajaxFun = getDailyDriverTaxRegDetails
            if (row.name == '车辆') ajaxFun = getDailyCarDetails
            if (row.type == '运单') ajaxFun = getDailyWaybillDetails
            if (row.type == '发票') ajaxFun = getDailyInvoiceDetails
            let params = {
                startDatetime: this.date ? this.date[0] : '',
                endDatetime: this.date ? this.date[1] : '',
                enterpriseName: this.enterpriseName,
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                keyValue: this.params.keyValue,
                ...(row.type == '运力' ? {
                    status: Number(this.params.status)
                } : {}),
                ...(row.type != '运力' ? {
                    optType: row.id,
                    waybillID: this.params.waybillID
                } : {})
            }
            ajaxFun(params).then(res => {
                this.detailTable = res.DataList
                this.pagination.total = Number(res.totalRowCount);
            }).finally(() => {
                this.loading = false
            })
        },
        searchDetail() {
            this.loading = true
            this.pagination.page = 1
            this.getDetailData(this.detailType)
        },
        // 清空搜索
        resetDetailForm() {
            this.loading = true
            this.params = {
                status: '0',
                keyValue: '',
                waybillID: ''
            }
            this.pagination.page = 1
            this.getDetailData(this.detailType)
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.loading = true
            this.pagination.page = e
            this.getDetailData(this.detailType)
        },
        paginationSizeChange(e) {
            this.pagination.pagesize = e
            this.loading = true
            this.getDetailData(this.detailType)
        }
    },
    components: {
        DriverInfo
    },
}
</script>

<style lang="scss" scoped>
.btnWrap {
    width: 100%;
    text-align: center;
    margin-top: 32px;
}

.facilityBox {
    .topbtns {
        padding-bottom: 10px;
        margin: 0px 0px 10px;
        border-bottom: 1px solid #eee;
    }

    // margin-top: 30px;
}

.searchBox {
    display: flex;
}

.deep_dialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
        border-top: 1px solid #eee;
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        padding: 20px;
    }

    ::v-deep .el-dialog__headerbtn {
        top: 10px
    }
}
</style>